<template>
  <div>
    <div v-for="(data, index) in reportData.dataForExportPdf" :key="index">
      <PdfContentHeader
        v-if="index % pdfOrientationLimit === 0"
        :dataProp="reportData"
        :pageProp="index / pdfOrientationLimit + 1"
        :reportDisplaySettingProp="reportDisplaySetting"
        :totalPageCountProp="totalPageCount"
      ></PdfContentHeader>
      <PdfContentBody
        :dataProp="data"
        :reportDisplaySettingProp="reportDisplaySetting"
      ></PdfContentBody>
      <div
        v-if="index % pdfOrientationLimit === pdfOrientationLimit - 1"
        class="html2pdf__page-break"
      ></div>
    </div>
  </div>
</template>

<script>
import PdfContentHeader from './PdfContentHeader.vue'
import PdfContentBody from './PdfContentBody.vue'

export default {
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object
  },
  components: {
    PdfContentHeader,
    PdfContentBody
  },
  data() {
    return {
      limit: 37,
      reportData: {
        date: null,
        companyName: null,
        toDate: null,
        accountMonth: null,
        data: [],
        totalTrialBalanceDebit: null,
        totalTrialBalanceCredit: null,
        totalProfitAndLossStatementDebit: null,
        totalProfitAndLossStatementCredit: null,
        totalBalanceSheetDebit: null,
        totalBalanceSheetCredit: null,
        netProfitAndLossStatementDebit: null,
        netProfitAndLossStatementCredit: null,
        netBalanceSheetDebit: null,
        netBalanceSheetCredit: null,
        finalProfitAndLossStatementDebit: null,
        finalProfitAndLossStatementCredit: null,
        finalBalanceSheetDebit: null,
        finalBalanceSheetCredit: null,
        dataForExportPdf: []
      },
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      }
    }
  },
  computed: {
    pdfOrientationLimit() {
      if (this.reportDisplaySetting.pdfOrientation === 'portrait') {
        return this.limit
      } else {
        return this.limit - 12
      }
    },
    totalPageCount() {
      return Math.ceil(
        (this.reportData.data.length + 3) / this.pdfOrientationLimit
      )
    }
  },
  watch: {
    dataProp() {
      this.reportData = { ...this.dataProp }
      const dataForExportPdf = [
        ...this.reportData.data.map(el => ({
          ...el,
          type: 'detail-data'
        })),
        {
          type: 'summary-data-1',
          title: 'รวมทั้งสิ้น',
          trialBalanceDebit: this.reportData.totalTrialBalanceDebit,
          trialBalanceCredit: this.reportData.totalTrialBalanceCredit,
          profitAndLossStatementDebit: this.reportData
            .totalProfitAndLossStatementDebit,
          profitAndLossStatementCredit: this.reportData
            .totalProfitAndLossStatementCredit,
          balanceSheetDebit: this.reportData.totalBalanceSheetDebit,
          balanceSheetCredit: this.reportData.totalBalanceSheetCredit
        },
        {
          type: 'summary-data-2',
          title: 'กำไร/ขาดทุนสุทธิ',
          trialBalanceDebit: null,
          trialBalanceCredit: null,
          profitAndLossStatementDebit: this.reportData
            .netProfitAndLossStatementDebit,
          profitAndLossStatementCredit: this.reportData
            .netProfitAndLossStatementCredit,
          balanceSheetDebit: this.reportData.netBalanceSheetDebit,
          balanceSheetCredit: this.reportData.netBalanceSheetCredit
        },
        {
          type: 'summary-data-3',
          title: '',
          trialBalanceDebit: null,
          trialBalanceCredit: null,
          profitAndLossStatementDebit: this.reportData
            .finalProfitAndLossStatementDebit,
          profitAndLossStatementCredit: this.reportData
            .finalProfitAndLossStatementCredit,
          balanceSheetDebit: this.reportData.finalBalanceSheetDebit,
          balanceSheetCredit: this.reportData.finalBalanceSheetCredit
        }
      ]
      this.reportData.dataForExportPdf = dataForExportPdf
    },
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
      }
    }
  }
}
</script>

<style></style>
