<template>
  <div class="text-sm">
    <!-- company name -->
    <div class="d-flex justify-content-between">
      <div class="w-100 text-center text-md font-weight-bold">
        <span>{{ dataProp.companyName }}</span>
      </div>
    </div>

    <!-- datetime / duration / page number -->
    <div class="d-flex justify-content-between pt-2 pb-3">
      <div class="w-25">
        <span>{{ getDate(dataProp.date) }}</span>
        <br />
        <span>{{ getTime(dataProp.date) }}</span>
      </div>
      <div class="w-50 text-center">
        <span>
          {{ `กระดาษทำการ`.split('').join(' ') }}
        </span>
        <br />
        <span> เพียง {{ getThaiDatePhrase(dataProp.toDate) }} </span>
      </div>
      <div
        class="w-25 text-right d-flex align-items-center justify-content-end"
      >
        <span>
          หน้า {{ pageProp }}/{{
            Number(totalPageCountProp) > 1 ? totalPageCountProp : '1'
          }}
        </span>
      </div>
    </div>

    <!-- report title -->
    <!-- <div class="text-center my-3">
      <span>
        {{ `กระดาษทำการ`.split('').join(' ') }}
      </span>
    </div> -->

    <!-- table header -->
    <div class="d-flex">
      <div
        :class="`table-header-width-empty-${reportDisplaySetting.pdfOrientation} text-center`"
      ></div>
      <div
        :class="`table-header-width-${reportDisplaySetting.pdfOrientation} table-header-border text-center`"
      >
        <span> งบทดลอง </span>
      </div>
      <div
        :class="`table-header-width-${reportDisplaySetting.pdfOrientation} table-header-border text-center`"
      >
        <span> งบกำไรขาดทุน </span>
      </div>
      <div
        :class="`table-header-width-${reportDisplaySetting.pdfOrientation} table-header-border text-center`"
      >
        <span> งบดุล </span>
      </div>
    </div>

    <!-- table subheader -->
    <div class="d-flex">
      <div
        :class="`account-id-col-${reportDisplaySetting.pdfOrientation} table-sub-header-border`"
      >
        <span>รหัสบัญชี</span>
      </div>
      <div
        :class="`account-name-col-${reportDisplaySetting.pdfOrientation} table-sub-header-border`"
      >
        <span>ชื่อบัญชี</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>เดบิต</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>เครดิต</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>เดบิต</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>เครดิต</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>เดบิต</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>เครดิต</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { datetimeMixins } from '@/mixins'
import * as datetimeUtils from '@/utils/datetime'

export default {
  mixins: [datetimeMixins],
  props: {
    dataProp: Object,
    pageProp: Number,
    reportDisplaySettingProp: Object,
    totalPageCountProp: Number
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
      }
    },
    getDate(value) {
      const today = this.mxConvertToDatetimeBuddhistFormat(moment().toDate())
      const date = today.split(' ')[0]
      return date
    },
    getTime(value) {
      const today = this.mxConvertToDatetimeBuddhistFormat(moment().toDate())
      const time = today.split(' ')[1].replace('(', '').replace(')', '') + ' น.'
      return time
    },
    getThaiDatePhrase(time) {
      return datetimeUtils.getThaiDatePhrase(time)
    }
  }
}
</script>

<style scoped>
.table-header-border {
  border-bottom: 1px solid black;
  padding: 5px 0px 10px 0px;
  margin: 0px 2px;
}
.table-sub-header-border {
  border-bottom: 1px solid black;
  padding: 5px 0px 10px 0px;
  margin: 5px 2px;
}
/* Portrait */
.table-header-width-empty-portrait {
  width: 19%;
}
.table-header-width-portrait {
  width: 27%;
}
.account-id-col-portrait {
  width: 8%;
}
.account-name-col-portrait {
  width: 11%;
}
.account-balance-col-portrait {
  width: 13.5%;
}
/* Landscape */
.table-header-width-empty-landscape {
  width: 25%;
}
.table-header-width-landscape {
  width: 25%;
}
.account-id-col-landscape {
  width: 8%;
}
.account-name-col-landscape {
  width: 17%;
}
.account-balance-col-landscape {
  width: 12.5%;
}
</style>
