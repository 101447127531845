import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=ca8ba62e&scoped=true&"
import script from "./MenuBar.vue?vue&type=script&lang=js&"
export * from "./MenuBar.vue?vue&type=script&lang=js&"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=ca8ba62e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca8ba62e",
  null
  
)

export default component.exports