<script>
import { mapState } from 'vuex'

import MenuBar from './components/MenuBar.vue'
import ReportHistory from './components/ReportHistory.vue'
import ReportSetting from './components/ReportSetting.vue'

export default {
  components: { MenuBar, ReportSetting, ReportHistory },
  data() {
    return {
      shouldUpdateReportHistory: false,
      reportData: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    reportSettingGenerateReportHandler() {
      this.shouldUpdateReportHistory = true
    },
    updateReportHistorySuccessfulHandler() {
      this.shouldUpdateReportHistory = false
    },
    applyReportDataHandler(el) {
      this.reportData = { ...el }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <span class="text-xxxl text-white" style="text-shadow: 2px 2px grey;">
          ออกรายงาน กระดาษทำการ
        </span>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>
    <!-- ส่วนการตั้งค่าออกรายงาน -->
    <b-row v-if="user && user.permission.reportTrialBalance.canPrint">
      <b-col cols="12">
        <report-setting
          class="mt-3"
          :reportDataProp="reportData"
          @generateReportButtonClicked="reportSettingGenerateReportHandler"
        ></report-setting>
      </b-col>
    </b-row>
    <!-- ส่วนประวัติออกรายงาน -->
    <b-row>
      <b-col cols="12">
        <report-history
          class="mt-4"
          :shouldUpdateReportHistoryProp="shouldUpdateReportHistory"
          @updateReportHistorySuccessful="updateReportHistorySuccessfulHandler"
          @applyReportData="applyReportDataHandler"
        ></report-history>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
