var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":false,"enable-download":true,"preview-modal":true,"manual-pagination":true,"pdf-content-width":"98%","html-to-pdf-options":{
      margin: 5,
      filename: 'รายงานกระดาษทำการ',
      html2canvas: {
        scale: 2
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: _vm.reportDisplaySetting.pdfOrientation
      }
    }},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('pdf-content',{attrs:{"dataProp":_vm.workingPaperData,"reportDisplaySettingProp":_vm.reportDisplaySetting}})],1)]),_c('error-modal',{attrs:{"displayProp":!!_vm.errMsg,"errorMessageProp":_vm.errMsg},on:{"modalClosed":function($event){_vm.errMsg = null}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }