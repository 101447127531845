<template>
  <div>
    <!-- detail data -->
    <div v-if="dataObj.type === 'detail-data'" :class="`text-sm d-flex`">
      <div :class="`account-number-col-${reportDisplaySetting.pdfOrientation}`">
        <span>{{ dataObj.accountNumber }}</span>
      </div>
      <div :class="`account-name-col-${reportDisplaySetting.pdfOrientation}`">
        <span>
          {{ addIndent(`${dataObj.accountName}`) }}
        </span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          dataObj.trialBalanceDebit === null
            ? dataObj.trialBalanceDebit
            : +dataObj.trialBalanceDebit >= 0
            ? mxNumeralThousandSeperate(dataObj.trialBalanceDebit)
            : `(${mxNumeralThousandSeperate(dataObj.trialBalanceDebit * -1)})`
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          dataObj.trialBalanceCredit === null
            ? dataObj.trialBalanceCredit
            : +dataObj.trialBalanceCredit >= 0
            ? mxNumeralThousandSeperate(dataObj.trialBalanceCredit)
            : `(${mxNumeralThousandSeperate(dataObj.trialBalanceCredit * -1)})`
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          dataObj.profitAndLossStatementDebit === null
            ? dataObj.profitAndLossStatementDebit
            : +dataObj.profitAndLossStatementDebit >= 0
            ? mxNumeralThousandSeperate(dataObj.profitAndLossStatementDebit)
            : `(${mxNumeralThousandSeperate(
                dataObj.profitAndLossStatementDebit * -1
              )})`
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          dataObj.profitAndLossStatementCredit === null
            ? dataObj.profitAndLossStatementCredit
            : +dataObj.profitAndLossStatementCredit >= 0
            ? mxNumeralThousandSeperate(dataObj.profitAndLossStatementCredit)
            : `(${mxNumeralThousandSeperate(
                dataObj.profitAndLossStatementCredit * -1
              )})`
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          dataObj.balanceSheetDebit === null
            ? dataObj.balanceSheetDebit
            : +dataObj.balanceSheetDebit >= 0
            ? mxNumeralThousandSeperate(dataObj.balanceSheetDebit)
            : `(${mxNumeralThousandSeperate(dataObj.balanceSheetDebit * -1)})`
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          dataObj.balanceSheetCredit === null
            ? dataObj.balanceSheetCredit
            : +dataObj.balanceSheetCredit >= 0
            ? mxNumeralThousandSeperate(dataObj.balanceSheetCredit)
            : `(${mxNumeralThousandSeperate(dataObj.balanceSheetCredit * -1)})`
        }}</span>
      </div>
    </div>
    <!-- summary data -->
    <div
      v-if="dataObj.type.includes('summary-data')"
      class="text-sm d-flex my-2"
    >
      <div
        :class="
          `account-number-col-${reportDisplaySetting.pdfOrientation} pt-2 ${
            dataObj.type === 'summary-data-3' ? 'pb-2' : ''
          }`
        "
      ></div>
      <div
        :class="
          `account-name-col-${
            reportDisplaySetting.pdfOrientation
          } text-left pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{ dataObj.title }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation} ${
            dataObj.type === 'summary-data-1' ||
            dataObj.type === 'summary-data-2'
              ? 'summary-data-border-top'
              : ''
          } text-right pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{
          dataObj.trialBalanceDebit !== null
            ? mxNumeralThousandSeperate(dataObj.trialBalanceDebit)
            : null
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation} ${
            dataObj.type === 'summary-data-1' ||
            dataObj.type === 'summary-data-2'
              ? 'summary-data-border-top'
              : ''
          } text-right pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{
          dataObj.trialBalanceCredit !== null
            ? mxNumeralThousandSeperate(dataObj.trialBalanceCredit)
            : null
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation} ${
            dataObj.type === 'summary-data-1' ||
            dataObj.type === 'summary-data-2' ||
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-top'
              : ''
          } ${
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-bottom'
              : ''
          } text-right pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{
          dataObj.profitAndLossStatementDebit !== null
            ? mxNumeralThousandSeperate(dataObj.profitAndLossStatementDebit)
            : null
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation} ${
            dataObj.type === 'summary-data-1' ||
            dataObj.type === 'summary-data-2' ||
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-top'
              : ''
          } ${
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-bottom'
              : ''
          } text-right pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{
          dataObj.profitAndLossStatementCredit !== null
            ? mxNumeralThousandSeperate(dataObj.profitAndLossStatementCredit)
            : null
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation} ${
            dataObj.type === 'summary-data-1' ||
            dataObj.type === 'summary-data-2' ||
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-top'
              : ''
          } ${
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-bottom'
              : ''
          } text-right pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{
          dataObj.balanceSheetDebit !== null
            ? mxNumeralThousandSeperate(dataObj.balanceSheetDebit)
            : null
        }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation} ${
            dataObj.type === 'summary-data-1' ||
            dataObj.type === 'summary-data-2' ||
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-top'
              : ''
          } ${
            dataObj.type === 'summary-data-3'
              ? 'summary-data-border-bottom'
              : ''
          } text-right pt-2 ${dataObj.type === 'summary-data-3' ? 'pb-2' : ''}`
        "
      >
        <span>{{
          dataObj.balanceSheetCredit !== null
            ? mxNumeralThousandSeperate(dataObj.balanceSheetCredit)
            : null
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'

import { numberMixins } from '@/mixins'

export default {
  mixins: [numberMixins],
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      dataObj: {
        accountNumber: null,
        accountName: null,
        accountLevel: null,
        accountType: null,
        trialBalanceDebit: null,
        trialBalanceCredit: null,
        profitAndLossStatementDebit: null,
        profitAndLossStatementCredit: null,
        balanceSheetDebit: null,
        balanceSheetCredit: null,
        type: null
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    dataProp() {
      this.assignDataFromProp('dataProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('dataProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'dataProp':
          this.dataObj = { ...this.dataProp }
          break
      }
    },
    addIndent(word) {
      return word
    }
  }
}
</script>

<style scoped>
/* Portrait */
.account-number-col-portrait {
  width: 8%;
}
.account-name-col-portrait {
  width: 11%;
  white-space: nowrap;
  overflow: hidden;
}
.account-balance-col-portrait {
  width: 13.5%;
}
/* Landscape */
.account-number-col-landscape {
  width: 8%;
}
.account-name-col-landscape {
  width: 17%;
  white-space: nowrap;
  overflow: hidden;
}
.account-balance-col-landscape {
  width: 12.5%;
}
/* Summary data */
.summary-data-border-top {
  border-top: 1px solid black;
}
.summary-data-border-bottom {
  border-bottom: 1px solid black;
}
</style>
