<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import ExportPdf from './ExportPdf.vue'
import ReportDisplaySettingModal from './ReportDisplaySettingModal.vue'

export default {
  components: { ExportPdf, ReportDisplaySettingModal },
  props: {
    reportDataProp: Object
  },
  data() {
    return {
      exportPdfClicked: false,
      accountMonthOptions: [
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
        { value: '24', text: '24' }
      ],
      accountMonth: '01',
      remark: null,
      displayUnavailableAccount: false,
      saveReportSetting: false,
      requestType: 'PERPETUAL',
      requestTypeOptions: [
        { value: 'PERPETUAL', text: 'แบบต่อเนื่อง' },
        { value: 'PERIODIC', text: 'แบบต้นงวดปลายงวด' }
      ],
      workingPaperData: {},
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      isReportDisplaySettingModalShow: false
    }
  },
  computed: {
    ...mapState({
      stateAuthCompany: (state) => state.auth.company
    }),
    displayAccountMonthPhrase() {
      if (!_.isNil(this.accountMonth) && this.stateAuthCompany) {
        const { programStartDate, programEndDate } = this.stateAuthCompany
        const [startYear] = programStartDate.split('-')
        const [endYear] = programEndDate.split('-')
        const buddhistStartYear = `${Number(startYear) + 543}`
        const buddhistEndYear = `${Number(endYear) + 543}`
        const accountMonthNum = +this.accountMonth
        const monthArrays = [
          'มกราคม',
          'กุมภาพันธ์',
          'มีนาคม',
          'เมษายน',
          'พฤษภาคม',
          'มิถุนายน',
          'กรกฎาคม',
          'สิงหาคม',
          'กันยายน',
          'ตุลาคม',
          'พฤศจิกายน',
          'ธันวาคม'
        ]
        let year = accountMonthNum <= 12 ? buddhistStartYear : buddhistEndYear
        let month =
          accountMonthNum <= 12
            ? monthArrays[accountMonthNum - 1]
            : monthArrays[accountMonthNum - 13]
        return `( ${month} ${year} )`
      } else {
        return 'กรุณเลือกงวดที่ต้องการออกรายงาน'
      }
    },
    displayRequestTypePhrase() {
      switch (this.requestType) {
        case 'PERPETUAL':
          return '( PERPETUAL )'
        case 'PERIODIC':
          return '( PERIODIC )'
        default:
          return 'กรุณาเลือกประเภทที่ต้องการจะออกรายงาน'
      }
    }
  },
  watch: {
    reportDataProp() {
      if (!_.isEmpty(this.reportDataProp)) {
        const { requestType, accountMonth, remark, displayUnavailableAccount } =
          this.reportDataProp

        this.requestType = requestType
        this.accountMonth = accountMonth
        this.remark = remark
        this.displayUnavailableAccount = displayUnavailableAccount
        this.saveReportSetting = false
      }
    }
  },
  methods: {
    generateReportButtonClicked() {
      this.workingPaperData = {
        requestType: this.requestType,
        accountMonth:
          this.accountMonth && this.accountMonth.trim().length > 0
            ? this.accountMonth.trim()
            : null,
        remark:
          this.remark && this.remark.trim().length > 0
            ? this.remark.trim()
            : null,
        saveReportSetting: this.saveReportSetting,
        displayUnavailableAccount: this.displayUnavailableAccount
      }
      this.exportPdfClicked = true
    },
    reportGeneratedHandler() {
      this.exportPdfClicked = false

      if (this.saveReportSetting) {
        this.$emit('generateReportButtonClicked')
      }
    },
    reportDisplaySettingModalClosed(payload) {
      if (!_.isUndefined(payload)) {
        this.reportDisplaySetting = { ...payload }
      }
      this.isReportDisplaySettingModalShow = false
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px">
      <!-- topic -->
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">ตั้งค่าออกรายงาน</span>
        </b-col>
      </b-row>

      <!-- content -->
      <b-row>
        <!-- เลขบัญชี / ช่วงเวลา -->
        <b-col class="m-0 p-0 px-3" cols="12" lg="6">
          <div>
            <!-- งวด -->
            <div class="d-flex align-items-center my-3">
              <span class="mr-2">ออกรายงานงวดที่</span>
              <div class="mr-2">
                <div class="d-flex align-items-center">
                  <b-select
                    class="mr-2 select-field-fixed-large-width-size"
                    v-model="accountMonth"
                    :options="accountMonthOptions"
                    :disabled="exportPdfClicked"
                  ></b-select>
                  <span class="mr-2 text-secondary">
                    {{ displayAccountMonthPhrase }}
                  </span>
                </div>
              </div>
            </div>

            <!-- ประเภท -->
            <div class="d-flex align-items-center my-3">
              <span class="mr-2">ประเภท</span>
              <div class="mr-2">
                <div class="d-flex align-items-center">
                  <b-select
                    class="mr-2 request-type-input-field-fixed-width-size"
                    v-model="requestType"
                    :options="requestTypeOptions"
                    :disabled="exportPdfClicked"
                  ></b-select>
                  <span class="mr-2 text-secondary">
                    {{ displayRequestTypePhrase }}
                  </span>
                </div>
              </div>
            </div>

            <!-- บันทึกการตั้งค่า -->
            <div class="mt-4 text-sm">
              <div>
                <b-form-checkbox
                  v-model="saveReportSetting"
                  :disabled="exportPdfClicked"
                >
                  บันทึกการตั้งค่าออกรายงานนี้
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-col>

        <!-- หมายเหตุ / เงื่อนไข -->
        <b-col class="m-0 p-0 px-3" cols="12" lg="6">
          <div>
            <!-- หมายเหตุ -->
            <div class="my-3 d-flex align-items-center">
              <span class="mr-3">หมายเหตุ</span>
              <b-input
                placeholder="หมายเหตุ"
                v-model="remark"
                :disabled="exportPdfClicked"
              ></b-input>
            </div>

            <!-- เงื่อนไข -->
            <div>
              <!-- บ/ช ไม่เคลื่อนไหว -->
              <div class="d-flex mt-1">
                <div style="width: 250px">
                  <span>แสดงบัญชีไม่เคลื่อนไหว</span>
                </div>
                <div class="d-flex">
                  <b-form-radio
                    class="mr-5"
                    v-model="displayUnavailableAccount"
                    :value="true"
                    :disabled="exportPdfClicked"
                  >
                    ใช่
                  </b-form-radio>
                  <b-form-radio
                    v-model="displayUnavailableAccount"
                    :value="false"
                    :disabled="exportPdfClicked"
                  >
                    ไม่ใช่
                  </b-form-radio>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- hover line -->
      <hr />

      <!-- buttons -->
      <b-row>
        <b-col class="m-0 p-0">
          <div class="d-flex justify-content-start justify-content-lg-end">
            <b-button
              variant="primary"
              @click="generateReportButtonClicked()"
              :disabled="exportPdfClicked"
            >
              <b-spinner
                v-if="exportPdfClicked"
                variant="light"
                small
              ></b-spinner>
              <span v-else>ออกรายงาน</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="mx-3"
              @click="isReportDisplaySettingModalShow = true"
              :disabled="exportPdfClicked"
            >
              ตั้งค่าการแสดงผล
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- export pdf -->
      <b-row class="d-none">
        <b-col>
          <export-pdf
            :click="exportPdfClicked"
            :reportDisplaySettingProp="reportDisplaySetting"
            :workingPaperDataProp="workingPaperData"
            @reportGenerated="reportGeneratedHandler"
          ></export-pdf>
        </b-col>
      </b-row>

      <!-- setting modal -->
      <b-row>
        <b-col>
          <report-display-setting-modal
            :reportDisplaySettingProp="reportDisplaySetting"
            :modalShowProp="isReportDisplaySettingModalShow"
            @modalClosed="reportDisplaySettingModalClosed"
          ></report-display-setting-modal>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<style scoped>
.request-type-input-field-fixed-width-size {
  width: 170px;
}
.select-field-fixed-small-width-size {
  width: 60px;
}
.select-field-fixed-large-width-size {
  width: 80px;
}
</style>
